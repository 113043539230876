<template>
  <div class="home">
    <div class="login">
      <div class="flex">
        <div class="loginBg">
          <img src="@/assets/images/login_bg3.jpeg" alt="" />
        </div>
        <div class="loginBox">
          <h1>
            <img src="@/assets/images/logo2.png" alt="" />
          </h1>
          <p>데이터 아카이빙 시스템</p>
          <!-- <h2>로그인</h2> -->
          <div class="form">
            <p>이메일(아이디)</p>
            <input type="text" v-model="email" />
            <p>비밀번호</p>
            <div class="password">
              <input :type="type" v-model="password" @keyup.enter="submit" />
            </div>
            <el-checkbox v-model="saveEmail">아이디 저장하기</el-checkbox>
            <button @click="submit" class="point large">로그인</button>
            <div v-if="error" class="error">
              가입하지 않은 이메일 혹은 잘못된 비밀번호입니다.
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="copy">Copyright&copy; iconix All Rights Resrved.</p>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { login } from "@/api/index";
// import Cookies from "js-cookie";
export default {
  mixins: [format],
  data() {
    return {
      pwOn: false,
      type: "password",
      email: "",
      password: "",
      error: false,
      saveEmail: false,
    };
  },
  mounted() {
    // 저장된 아이디가 있다면 가져옵니다.
    if (localStorage.getItem("email")) {
      this.email = localStorage.getItem("email");
      this.saveEmail = true;
    }
  },
  activated() {
    // 저장된 아이디가 있다면 가져옵니다.
    if (localStorage.getItem("email")) {
      this.email = localStorage.getItem("email");
      this.saveEmail = true;
    }
  },
  methods: {
    handlePasswordOnOff() {
      this.pwOn == true ? (this.type = "password") : (this.type = "text");
      this.pwOn = !this.pwOn;
    },
    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
        // } else if (this.emailValidate(this.email) == false) {
        //   return alert("이메일을 형식이 맞지 않습니다.");
      } else if (this.password == "") {
        return alert("비밀번호를 입력해 주세요.");
      }

      let data = {
        email: this.email,
        password: this.password,
      };
      login(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("LOGIN", res.data.accesstoken);
          if (this.saveEmail) {
            localStorage.setItem("email", this.email);
          } else {
            localStorage.removeItem("email");
          }
          document.location.href = "/admin/dashboard";
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
